import React from 'react';
import get from 'lodash.get';
import HeroSlider from 'components/HeroSlider';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import url from 'helpers/url';
import Videos from 'blocks/Videos';
import { multilineHTML } from 'helpers/text';

import {
    Spacing,
    SharedStyles,
    Container,
    VideoBlock,
    NewVideoWithDesc,
    GenericBenefitList,
    GenericProductList,
    ImageTextTile,
    ListOfImg,
    ImageGrid,
    VideoWithInfo,
} from '../../kenra-storybook/index'

const {
    StSectionInfo,
    StSectionTitle,
    StLargeSectionTitle,
    StSectionBody,
    StSectionLink,
    VidTitle,
    StTitle,
} = SharedStyles;

function parseFragments(fragments, intl) {
    let fragmentDataMap = fragments.map(section => {
        let returnData = {};
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['img'] = fragmentData.value;
                    break;
                case 'Overlay':
                    returnData['overlay'] = fragmentData.value;
                    break;
                case 'Title':
                    returnData['title'] = fragmentData.value;
                    break;
                case 'Text':
                    returnData['text'] = fragmentData.value;
                    if (fragmentData.value !== null) {
                        returnData['text'] = fragmentData.value.replace(
                            '\\n',
                            '\n '
                        );
                    }
                    break;
                case 'ItemColor':
                    returnData['color'] = fragmentData.value;
                    break;
                case 'ProductLink':
                    if (fragmentData.value) {
                        returnData['link'] = '/product' + fragmentData.value;
                        returnData['linkText'] = intl.formatMessage({
                            id: 'product-view',
                        });
                    }
                    break;
                default:
                    break;
            }
        });
        return {
            image: returnData.img,
            overlay: returnData.overlay,
            title: returnData.title,
            text: returnData.text,
            itemColor: returnData.color,
            link: returnData.link,
            linkText: returnData.linkText,
        };
    });

    return fragmentDataMap;
}

const SAWLanding = ({ intl, page }) => {
    let section1Data = parseFragments(page.section1Data.fragments, null);
    let collectionData = parseFragments(page.collectionData.fragments, intl);
    let moreCollectionData = parseFragments(
        page.moreCollectionData.fragments,
        intl
    );
    let tutorials = page.tutorials;
    let youtubeLink = tutorials[0].youtubeLink;
    // let youtubeLink2 = tutorials[1].youtubeLink;

    let youTubeId = getYouTubeId(youtubeLink);
    //let youTubeId2 = getYouTubeId(youtubeLink2);
    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottom>
                <HeroSlider slides={page.banner} />
            </Spacing>
            <Spacing>
                <Container>
                    {section1Data && (
                        <GenericBenefitList
                            items={section1Data}
                            title={get(page, 'section1Title')}
                            embedTitleAndImage="true"
                            
                            bgImg={get(
                                page,
                                'section1Background.localFile.publicURL'
                            )}
                        />
                    )}
                </Container>
            </Spacing>
            <Spacing>
                <Container>
                    {moreCollectionData && (
                        <GenericBenefitList
                            disclaimer={intl.formatMessage({
                                id: 'saw-disclaimer',
                            })}
                            items={moreCollectionData}
                            title={get(page, 'section2Text')}
                        />
                    )}
                </Container>
            </Spacing>

            <Spacing
                backgroundImage={get(
                    page,
                    'backgroundImage.localFile.publicURL'
                )}
            >
                <StSectionInfo>
                    <Container fullWidth>
                        <StTitle>
                            <h2>{get(page, 'collectionTitle')}</h2>
                        </StTitle>
                    </Container>
                </StSectionInfo>
                {collectionData && (
                    <div>
                        <StTitle>
                            <h2>
                                {intl.formatMessage({
                                    id: 'saw-products',
                                })}
                            </h2>
                        </StTitle>
                        <GenericProductList
                            itemColor={page.collectionTitleColor}
                            items={collectionData}
                            split5050
                            white
                            invertTextColor
                        />
                    </div>
                )}
            </Spacing>
            <Spacing removeSpaceBottom>
                {page.gifImages && (
                    <ImageGrid
                        items={page.gifImages.map(gif => {
                            return {
                                img: get(gif, 'localFile.publicURL'),
                            };
                        })}
                    />
                )}
            </Spacing>
            <Spacing>
                <section>
                    <Container useLargeWidth>
                        <VideoWithInfo
                            img={getPreviewUrl(youTubeId)}
                            useImgOverlay
                            videoType="youtube"
                            autoPlay={true}
                            videoOptions={[{ src: getEmbedUrl(youTubeId) }]}
                        />
                    </Container>
                </section>
            </Spacing>
            <Spacing>
                <Videos
                    title={intl.formatMessage({ id: 'kenra-education-videos' })}
                    videos={page.educationVideos}
                    bgImage={get(
                        page,
                        'educationVideoImage.localFile.publicURL'
                    )}
                />
            </Spacing>
        </>
    );
};

export default injectIntl(SAWLanding);
