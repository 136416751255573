import React from 'react';
import get from 'lodash.get';

import urls from 'helpers/url';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import { useIntl, Link } from 'gatsby-plugin-react-intl';

import {
    Container,
    SharedStyles,
    VideoWrapList,
} from '../kenra-storybook/index';

const { StLinkMore, StSectionTitle, StLink } = SharedStyles;

export default function Videos(props) {
    let { title, showBottomButton, videos, bgImage, playlists, invert } = props;
    let intl = useIntl();
    videos = videos.slice(0, 3);

    return (
        <>
            {title && (
                <StSectionTitle>
                    <Container>
                        <h2>{title}</h2>
                        <StLink mobileHide>
                            <Link to={urls.videos}>
                                {intl.formatMessage({ id: 'video-view-all' })}
                            </Link>
                        </StLink>
                    </Container>
                </StSectionTitle>
            )}

            <Container>
                <VideoWrapList
                    showBtnMoreDesktop={!!showBottomButton}
                    linkMobileText={intl.formatMessage({
                        id: 'video-watch-more',
                    })}
                    linkMobileMore={urls.videos}
                    bgUrl={
                        bgImage ||
                        'https://images.ctfassets.net/6ntd78431879/iPPIvMVgj2A9BJ5e15Evh/0a1f1686bf91eef0bc0392e9bf1f1fb9/Kenra_finish3__1_.jpg'
                    }
                    invert={invert}
                    items={videos.map(video => {
                        let youtubeId = getYouTubeId(video.youtubeLink);

                        return {
                            img: getPreviewUrl(youtubeId),
                            title: get(video, 'title.title'),
                            // TODO:
                            // subtitle: '54k views — 2 weeks ago',
                            videoType: 'youtube',
                            autoPlay: true,
                            videoOptions: [
                                {
                                    src: playlists
                                        ? getPlaylistEmbedUrl(youtubeId)
                                        : getEmbedUrl(youtubeId),
                                },
                            ],
                        };
                    })}
                >
                    <StLinkMore>
                        <Link to={urls.videos}>
                            {intl.formatMessage({ id: 'video-watch-more' })}
                        </Link>
                    </StLinkMore>
                </VideoWrapList>
            </Container>
        </>
    );
}
